import axios from 'axios';
import AppConsts from '../../appconst';
import { setLoading } from "./common-slice";

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${process.env.API_BASE_URL}/Product/GetAllProductList`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductsByCollectionAndTag = createAsyncThunk(
  "product/fetchProductsByCollectionAndTag",
  async (data, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetAllProducts/CollectionId/${data.CollectionId}/Tag/${data.Tag}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        !response.data.length && thunkApi.dispatch(setLoading(false));
        const outputData = response.data?.map((item) => {
          const variants = JSON.parse(item?.variants)
          return {
            ...item, variants: variants,
            image: variants?.filter(e => e?.imagePath).map((e) => e.imagePath),
          };
        });
        return outputData;
        } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductsBySearch = createAsyncThunk(
  "product/fetchProductsBySearch",
  async (data, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetAllProducts/Search/${data}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        !response.data.length && thunkApi.dispatch(setLoading(false));
        const productData = response.data?.map((item) => {
          const variants = JSON.parse(item?.variants)
          const variantImages = variants?.map((variant) =>
            variant?.imagePath
          );
          return {
            ...item, variants: variants,
            image: (variantImages || []), 
          };
        });
        return productData;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductsByCollection = createAsyncThunk(
  "product/fetchProductsByCollection",
  async (CollectionId, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetProductsByCollection/CollectionId/${CollectionId}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data ? response.data.map( p => ({ ...p, image: [p.imagePath] })) : [];
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchProductById = createAsyncThunk(
  "product/fetchProductById",
  async (id, thunkApi) => {
    try {
      thunkApi.dispatch(setLoading(true));
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetProductDetailsById/${id}`, {
          headers: {
            "Accept":"application/json",
          }
        }
      )
      const { variants, ...product } = response.data;
      const { categoryFilterTag, filterTag } = AppConsts;
      let productDetails = {...product, variants: JSON.parse(variants),
        categoryTag: product.tag.split(",").filter(v => v.toLowerCase().includes(categoryFilterTag)).map(v => v.substring(v.lastIndexOf('_')+1)),
        filterTag: product.tag.split(",").filter(v => v.toLowerCase().includes(filterTag)).map(v => v.substring(v.lastIndexOf('_')+1))
      }
      return productDetails ? 
      { ...productDetails,
        imagePaths: [ ...productDetails.images.map(image => image) ]
      } : null;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong")
    }
  }
);


export const getProductsIdByUrlHandle = createAsyncThunk(
  "product/getProductsIdByUrlHandle",
  async ({ urlHandle, companyId }, thunkApi) => {
    try {
      thunkApi.dispatch(setLoading(true));
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetProductsIdByUrlHandle/${urlHandle}/${companyId}`, {
          headers: {
            "Accept": "application/json",
          }
        }
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchCategoryList = createAsyncThunk(
  "category/fetchCategorys",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Category/GetAllCategoryList`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const fetchSuggestBySearch = createAsyncThunk(
  "product/fetchSuggestBySearch",
  async (data, thunkApi) => {
    thunkApi.dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Product/GetSuggestProduct/Search/${data}/${localStorage.getItem('companyId')}`, {
          headers: {
            "Accept":"application/json",
          }
        });
        !response.data.length && thunkApi.dispatch(setLoading(false));
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        filterProducts: [],
        filterSuggest:[],
        filterOptions: [],
        loading: false,
        product: null,
        productId: null,
        relatedProducts: [],
        categoryList: [],
    },
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
        setFilterOptions(state, action) {
            state.filterOptions = action.payload;
        },
        resetProductState(state, action) {
            state.products = [];
            state.relatedProducts = [];
            state.filterProducts = [];
            state.filterSuggest=[];
            state.filterOptions = [];
            state.product = null;
            state.productId = null;
            state.categoryList = [];
        }
    },
    extraReducers: {
      [fetchProductsByCollectionAndTag.fulfilled]: (state, action) => {
        state.loading = false
        state.filterProducts = action.payload
      },
      [fetchProductsBySearch.fulfilled]: (state, action) => {
        state.loading = false
        state.filterProducts = action.payload
      },
      [fetchSuggestBySearch.fulfilled]: (state, action) => {
        state.loading = false
        state.filterSuggest = action.payload
      },
      [fetchProductById.fulfilled]: (state, action) => {
        state.loading = false
        state.product = action.payload
      },
      [getProductsIdByUrlHandle.fulfilled]: (state, action) => {
        state.loading = false
        state.productId = action.payload
      },
      [fetchProductsByCollection.fulfilled]: (state, action) => {
        state.loading = false
        state.relatedProducts = action.payload
      },
      [fetchCategoryList.fulfilled]: (state, action) => {
        state.loading = false
        state.categoryList = action.payload
      },
    },
	
});

export const thunks = {
  fetchProductsByCollectionAndTag, fetchProductById, fetchProductsByCollection, fetchProductsBySearch,fetchCategoryList,fetchSuggestBySearch
};

export const { setProducts, setFilterOptions, resetProductState } = productSlice.actions;
export default productSlice.reducer;
