import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const Logo = ({ logoClass, companyList }) => {

  return (
    <div className={clsx(logoClass)}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        {companyList?.companyLogo ? (
          <>
            <img  
              src={companyList?.companyLogo}
              alt="Company Logo"
            />
            </>
        ) : (
          <>
           <img  
              style={{width: '200px'}}
              src= "https://zemez.io/html/wp-content/uploads/sites/9/2017/10/logo.png"
              alt="Company Logo"
            />
          </> 
        )} 
      </Link>
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
