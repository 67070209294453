import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { fetchPageHeaderDetails } from "../../store/slices/common-slice";
import { generateAlternateLoginId } from "../../apputils";
import { Link } from "react-router-dom";
import { getNavigationPath } from '../.././utils/urlutils';

const NavMenu = ({ menuWhiteClass, sidebarMenu }) => {
  const dispatch = useDispatch();
  const { megaMenuList } = useSelector((state) => state.common);
  
  const dispatchPageDependentActions = useCallback(() => {
    localStorage.getItem("accessToken") && dispatch(fetchPageHeaderDetails());
  },[dispatch]);

  useEffect(() => {
    if (
      !localStorage.getItem("accessToken") &&
      !localStorage.getItem("alternateLoginId")
    ) {
      localStorage.setItem("alternateLoginId", generateAlternateLoginId());
    }
    dispatchPageDependentActions();
  }, [dispatchPageDependentActions]);

  const buildMenuHierarchy = (menuList) => {
    const menuMap = {};
    const addedToHierarchy = new Set();
  
    menuList?.forEach((menu) => {
      menuMap[menu.menu] = { ...menu, subMenus: [] };
    });
  
    const rootMenus = [];
  
    menuList?.forEach((menu) => {
      if (
        menu.parentMenu &&
        menu.parentMenu !== menu.menu &&
        menuMap[menu.parentMenu]
      ) {
        menuMap[menu.parentMenu].subMenus.push(menuMap[menu.menu]);
        addedToHierarchy.add(menu.menu);
      } else if (!addedToHierarchy.has(menu.menu)) {
        rootMenus.push(menuMap[menu.menu]);
        addedToHierarchy.add(menu.menu);
      }
    });
  
    const sortMenuItemsRecursively = (menus) => {
      return menus
        .sort((a, b) => a.displayPreference - b.displayPreference)
        .map((menu) => ({
          ...menu,
          subMenus: sortMenuItemsRecursively(menu.subMenus),
        }));
    };
  
    return sortMenuItemsRecursively(rootMenus);
  };  

  const renderMenu = (menu) => {
    const isExternal = /^https?:\/\//.test(menu?.link);
    const navigate = menu?.link ? getNavigationPath(menu.link) : '';

    return (
      <li key={menu.id}>
        {isExternal ? (
          <a href={navigate} rel="noopener noreferrer" className="submenu-list">
            {menu?.menu}
            {menu.subMenus.length > 0 && (
              <span>
                {sidebarMenu ? (
                  <i className="fa fa-angle-right"></i>
                ) : (
                  <i className="fa fa-chevron-down" />
                )}
              </span>
            )}
          </a>
        ) : (
          <Link to={menu?.link} className="main-menu">
            {menu.menu}
            {menu.subMenus.length > 0 && (
              <span>
                {sidebarMenu ? (
                  <i className="fa fa-angle-right"></i>
                ) : (
                  <i className="fa fa-chevron-down" />
                )}
              </span>
            )}
          </Link>
        )}
        {menu.subMenus.length > 0 && (
          <ul
            className="mega-menu mega-menu-padding"
            style={{
              maxHeight: "330px",
              overflowY: "auto",
              width: "350px",
              scrollbarWidth: "thin",
              padding: '20px 30px'
            }}
          >
            {menu.subMenus.map((subMenu) => renderMenu(subMenu))}
          </ul>
        )}
      </li>
    );
  };

  const nestedMenuList = buildMenuHierarchy(megaMenuList);

  return (
    <div
      className={clsx(
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      )}
    >
      <nav>
        <ul>
          {nestedMenuList.map((menu) => renderMenu(menu))}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenu;