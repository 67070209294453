import axios from 'axios';
import cogoToast from "cogo-toast";

const { createSlice , createAsyncThunk} = require('@reduxjs/toolkit');

export const fetchRatingsByProductId = createAsyncThunk(
    "fetchRatingsByProductId",
    async (data, thunkApi) => {
      try {
        const response = await axios.get(`/RatingsReviews/GetRatingsByProductId/ProductId/${data}`)
        return response.data;
        
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );

  export const addRatings = createAsyncThunk(
    "addRatings",
    async (data, thunkApi) => {
      try {
        const response = await axios.post(`/RatingsReviews/SaveRatingsReviews`, {...data,companyId:localStorage.getItem('companyId')}, )
        return response.data;
        
      } catch (error) {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
      }
    }
  );


const ratingSlice = createSlice({
    name: "rating",
    initialState: {
      productRatings: [],
    },
    extraReducers:{
      [fetchRatingsByProductId.fulfilled]: (state, action) => {
        state.loading = false
        state.productRatings = action.payload
      },
      [addRatings.fulfilled]: (state, action) => {
        state.loading = false
        cogoToast.success("Rating added successfully", {position: "top-center"});
      },
    }
});

export const thunks = {
   fetchRatingsByProductId, addRatings
}; 
export default ratingSlice.reducer;