import cogoToast from 'cogo-toast';
import { getLoggedInUserId } from '../../apputils';
import axios from 'axios';
import AppConsts from '../../appconst';
import { fetchPageHeaderDetails } from './common-slice';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const fetchAllWishlist = createAsyncThunk(
  "fetchAllWishlist",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Wishlist/GetAllWishlist/userId/${getLoggedInUserId()}`, {
        params:{
          CompanyId: localStorage.getItem('companyId'),
         },        
        headers: {
          'X-Bypass-Token-Check': true, // Bypass token check for this request
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);


export const deleteFromWishlist = createAsyncThunk(
  "deleteFromWishlist",
  async (id, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Wishlist/DeleteWishlist/${id}`, {
          headers: {
            "Accept":"application/json",
          },
        });
        thunkApi.dispatch(fetchPageHeaderDetails());
        thunkApi.dispatch(fetchAllWishlist());
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteAllFromWishlist = createAsyncThunk(
  "deleteAllFromWishlist",
  async (_, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Wishlist/DeleteAllWishlist/userId/${getLoggedInUserId()}`, {
          headers: {
            "Accept":"application/json",
          },
        });
        thunkApi.dispatch(fetchPageHeaderDetails());
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const addToWishlist = createAsyncThunk(
  "addToWishlist",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Wishlist/SaveWishlist`,
        {...data, userId: getLoggedInUserId(),companyId:localStorage.getItem('companyId')},
        {
          headers: {
            "Accept":"application/json",
          },
        });
        thunkApi.dispatch(fetchPageHeaderDetails());
        thunkApi.dispatch(fetchAllWishlist());
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState: {
        wishlistItems: [],
        wishlists: [],
    },
    reducers: {
        // addToWishlist(state, action) {
        //     const isInWishlist = state.wishlistItems.findIndex(item => item.id === action.payload.id);
        //     if(isInWishlist > -1){
        //         cogoToast.info("Product already in wishlist", {position: "bottom-left"});
        //     } else {
        //         state.wishlistItems.push(action.payload);
        //         cogoToast.success("Added To wishlist", {position: "bottom-left"});
        //     }
            
        // },
        // deleteFromWishlist(state, action){
        //     state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
        //     cogoToast.error("Removed From Wishlist", {position: "bottom-left"});
        // },
        // deleteAllFromWishlist(state){
        //     state.wishlistItems = []
        // },
        setWishlist(state, action){
            state.wishlists = action.payload;
        }
    },
    extraReducers:{
        [fetchAllWishlist.fulfilled]: (state, action) => {
          state.loading = false
          state.wishlists = action.payload
        },
        [deleteFromWishlist.fulfilled]: (state, action) => {
          state.loading = false
          cogoToast.error("Removed From Wishlist", {position: "top-center"});
        }, 
        [addToWishlist.fulfilled]: (state, action) => {
        state.loading = false
        cogoToast.success("Added To wishlist", {position: "top-center"});
        },
      }
});

export const { setWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
