import React from "react";
import ContentLoader from "react-content-loader";

const InlineLoader = ({ width, height }) => (
  <ContentLoader
    speed={1}
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    style={{ display: "inline-block", verticalAlign: "middle" }}
  >
    <rect x="0" y="0" rx="3" ry="3" width={width} height={height} />
  </ContentLoader>
);

export default InlineLoader;
