import { createSlice ,createAsyncThunk} from '@reduxjs/toolkit'
import AppConsts from '../../appconst';
import axiosInstance from '../../auth/AxiosInstance';

export const applyOffers = createAsyncThunk(
'applyOffers',
async (data, thunkApi) => {
    try{
        const response = await axiosInstance.get(`${AppConsts.apiBaseUrl}/ApplyOffer/PurchaseAmount/${data}`)
        return response.data;
    }
    catch(error)
    {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
    
})

export const applyOfferByCode = createAsyncThunk(
'applyOfferByCode',
async (data, thunkApi) => {
    try{
        const response = await axiosInstance.get(`${AppConsts.apiBaseUrl}/ApplyOffer/PurchaseAmount/${data.amount}/Coupon/${data.couponCode}`)
        return response.data;
    }
    catch(error)
    {
        return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
    
})

const offerSlice = createSlice({
  name: "offer",
  initialState: {
    offerDetails: null,
  },
  reducers: {
    resetOfferState(state, action) {
        state.offerDetails = null;
    }
  },
  extraReducers: {
    [applyOffers.pending]: (state,action) => {
        state.loading = true
    },
    [applyOffers.fulfilled]: (state,action) => {
        state.loading = false;
        state.offerDetails = { ...action.payload, usedCouponCode: action.payload.couponCode, couponCode: '' };
    },
    [applyOfferByCode.pending]: (state,action) => {
        state.loading = true
    },
    [applyOfferByCode.fulfilled]: (state,action) => {
        state.loading = false;
        state.offerDetails = { ...action.payload, usedCouponCode: action.payload.couponCode, couponCode: '' };
    }
  }
})

export const thunks = {
    applyOffers, applyOfferByCode
};
export const { resetOfferState } = offerSlice.actions;

export default offerSlice.reducer