import axios from 'axios';
import AppConsts from '../../appconst';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fulfillmentStatus = createAsyncThunk(
    "fulfillmentStatus",
    async (OrderId, thunkApi) => {
        try {
            const response = await axios.get(
                `${AppConsts.apiBaseUrl}/Fulfillment/GetAllFulfillmentListByOrderId/${OrderId}`,
                {
                    params: {
                      CompanyId: localStorage.getItem("companyId"),
                    },
                    headers: {
                      Accept: "application/json",
                    },
                });
            return response.data;

        } catch (error) {
            return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
        }
    }
);

export const fulfillmentInvoice = createAsyncThunk(
    "fulfillmentInvoice",
    async (OrderNo, thunkApi) => {
        try {

            const response = await axios.get(
                `${AppConsts.apiBaseUrl}/Fulfillment/GetInvoiceDetailsById/${OrderNo}/${localStorage.getItem("companyId")}`,
                {
                    headers: {
                      Accept: "application/json",
                    },
                });
            return response.data;

        } catch (error) {
            return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
        }
    }
);

export const updateFulfillment = createAsyncThunk(
    "updateFulfillment",
    async (data, thunkApi) => {
      try {
        const response = await axios.put(
          `${AppConsts.apiBaseUrl}/Fulfillment/UpdateFulfillment`,
          data,  
          {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json", 
            },
          }
        );
        return response.data;
      } catch (error) {
        return thunkApi.rejectWithValue(
          error instanceof Error ? error.message : "Something went wrong"
        );
      }
    }
  );
const fulfillmentSlice = createSlice({
    name: "fullfillment",
    initialState: {
        fulfillmentList: [],
        invoiceDetails : null,
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder
        .addCase(fulfillmentStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.fulfillmentList = action.payload;
        })
        .addCase(fulfillmentInvoice.pending, (state) => {
            state.loading = true;
        })
        .addCase(fulfillmentInvoice.fulfilled, (state, action) => {
            state.loading = false;
            state.invoiceDetails = action.payload;
        })
        .addCase(fulfillmentInvoice.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload || "Failed to fetch invoice details";
        });
    },
});

export const thunks = {
    fulfillmentStatus,
    fulfillmentInvoice
};

export default fulfillmentSlice.reducer;
